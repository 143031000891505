import React from "react"
import Routes from "../../src/routes/routes"
import { withRouter } from "react-router-dom"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "../AppInsights/AppInsights"
import { updateMoment } from "src/i18n/i18n"
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const App = () => {
  updateMoment()
  const rumConfig = {
    applicationId: 'ba4ef458-979d-4651-9972-07f445d56a3c',
    clientToken: 'pub2e95eaff894a806959de81c55d2f3685',
    site: 'us3.datadoghq.com',
    env: 'devtwo',
    service: 'supervisorweb',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [(url) => url.startsWith("https://api.flexcount-devtwo.com")],
    traceSampleRate: 100
  };

  datadogRum.init(rumConfig);
  datadogLogs.init({
    clientToken: 'pub2e95eaff894a806959de81c55d2f3685',
    site: 'us3.datadoghq.com',
    service: 'supervisorweb',
    env: 'devtwo',
    forwardErrorsToLogs: true,
  });
  const logWithRumContext = (message, context) => {
    const rumContext = datadogRum.getInternalContext();
    const combinedContext = {
      ...context,
      session_id: rumContext?.session_id,
      application_id: rumConfig.applicationId
    };
    datadogLogs.logger.log(message, combinedContext);
  };
  logWithRumContext('User action event', { custom_attribute: 'value' });

  datadogRum.setUser({
    id: '344',
    name: 'Rajesh Supervisorweb',
    email: 'supervisorweb@wisintl.com'
  })

  localStorage.setItem("isRefreshTokenInProgress", "false")
  return <Routes />
}

// export default withRouter(App)
export default withAITracking(reactPlugin, withRouter(App))

import * as crypto from "crypto-js"
import i18next from "i18next"
import moment from "moment"
import { store } from "src/redux/store"
import {
  TAG_DETAILS_HEADERS,
  TAG_STATUS,
  TagConstants,
  disallowedExtensions,
  validationConstants
} from "./constants"
import { languages } from "src/constants/common"
import axios from "axios"
import {
  setCsvColumnList,
  setTagFileData,
  setTagMappingData
} from "src/redux/slices/bulkSlice"
import Papa from "papaparse"
import { string } from "yup"
const INVALID_DATE = "Invalid Date"

export const getUniqueKey = () => {
  return (Math.random() + 1).toString(36).substring(7)
}

export const formatNumbers = (numToFormat: number, lan: string) => {
  if (numToFormat) return new Intl.NumberFormat(lan).format(numToFormat)
  else return ""
}

const currencyFormatRegex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g

export const formatToCurrency = amount => {
  return amount ? amount.replace(currencyFormatRegex, ",") : "-"
}

export const lTrimZeros = (input: number | string) => {
  // Convert the input to a string and parse to remove leading zeros
  return isNaN(Number(input)) ? input : parseInt(String(input), 10);
}


// To formatting the Ext, Qty, Price depending on language selection
export const formatWithCurrencyLang = amount => {
  if (
    (amount || amount === 0) &&
    i18next?.language?.toLowerCase() === languages?.FRENCH?.toLowerCase()
  ) {
    return `${amount}`.replace(currencyFormatRegex, ".")
  } else {
    return amount || amount === 0
      ? `${amount}`.replace(currencyFormatRegex, ",")
      : "-"
  }
}
export const createURLFromByteArray = encoded => {
  if (encoded) {
    try {
      const byteCharacters = atob(encoded)
      let byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)

      // Convert the byte array to a Blob
      const blob = new Blob([byteArray], { type: "application/octet-stream" })

      // Create a URL for the Blob
      return URL.createObjectURL(blob)
    } catch (err) {
      throw err
    }
  } else {
    console.error("No file info found")
  }
}

// To check Last occurence of decimal point and replace it with ","
const checkLastDot = (str: string) => {
  const last = str.lastIndexOf(".")
  return last !== -1
    ? str.slice(0, last) + str.slice(last).replace(".", ",")
    : str
}
export const showValuesWithDecimals = (value: any, decimals: any) => {
  if (value == 0) return parseFloat(value).toFixed(decimals).toString()
  if (value == undefined || value == null || value == "") return ""

  return parseFloat(value).toFixed(decimals)
}
function round(num, precision) {
  var base = 10 ** precision;
  return (Math.round(num * base) / base).toFixed(precision);
}
export const showValuesWithCurrency = (
  value: any,
  decimals: any,
  currency: any,
  isFloat: boolean = false
) => {
  let _tempVal
  if (value == undefined || value == null) return "-"
  if (isFloat) {
     //_tempVal = formatWithCurrencyLang(parseFloat(value)?.toFixed(decimals))
     _tempVal = formatWithCurrencyLang(round(parseFloat(value),decimals))
  } else {
    _tempVal = formatWithCurrencyLang(round(value,decimals))
  }
  if (i18next?.language?.toLowerCase() === languages?.FRENCH?.toLowerCase()) {
    if (decimals !== 0) {
      return `${checkLastDot(_tempVal)} ${currency ? currency : ""}`
    } else {
      return `${_tempVal} ${currency ? currency : ""}`
    }
  } else {
    return `${currency ? currency : ""}${_tempVal}`
  }
}
export const getKeys = (valKey: string) => {
  let keys = store.getState()?.auth?.keys
  let result = keys.find(key => {
    return key?.secretKey?.includes(valKey)
  })
  if (result) {
    return result.secretValue
  }
  return ""
}
export const decrypt = chiper => {
  const SecretKey: string = getKeys("UrlEncryptionSecretKey")
  const IV: string = getKeys("UrlEncryptionIVKey")
  if (SecretKey && IV) {
    const keyWA = crypto.enc.Utf8.parse(SecretKey)
    const ivWA = crypto.enc.Utf8.parse(IV)
    let decrypted = crypto.AES.decrypt(chiper, keyWA, { iv: ivWA })
    decrypted = decrypted.toString(crypto.enc.Utf8)
    return decrypted
  }
  return ""
}
export const replaceAll = (sourceStr, searchStr, replace) => {
  if (sourceStr && sourceStr.length) {
    return sourceStr.split(searchStr).join(replace)
  }
  return ""
}
export const isSuppressed = (currentIndex, field, data) => {
  if (currentIndex === 0) return false
  else {
    return data[currentIndex][field] === data[currentIndex - 1][field]
  }
}

export const isSuppressedReport = (
  currentIndex,
  field,
  data,
  colIndex,
  isSorted = false,
  columns: any = [],
  columnSource: string = "dataSource"
) => {
  if (currentIndex === 0) return false
  if (colIndex === 0) {
    let curRecord = isSorted ? data[currentIndex]["values"] : data[currentIndex]
    let prevRecord = isSorted
      ? data[currentIndex - 1]["values"]
      : data[currentIndex - 1]
    return (
      curRecord[field] !== null &&
      curRecord[field] !== undefined &&
      prevRecord[field] !== null &&
      prevRecord[field] !== undefined &&
      curRecord[field] === prevRecord[field]
    )
  }
  for (let i = colIndex; i >= 0; i--) {
    let key: string = (columns.length && columns[i][columnSource]) || ""
    if (!key) return false
    let curRecord = isSorted ? data[currentIndex]["values"] : data[currentIndex]
    let prevRecord = isSorted
      ? data[currentIndex - 1]["values"]
      : data[currentIndex - 1]
    if (
      curRecord[key] === null ||
      curRecord[key] === undefined ||
      prevRecord[key] === null ||
      prevRecord[key] === undefined ||
      curRecord[key] !== prevRecord[key]
    )
      return false
  }
  return true
}
export const getQueryParam = (key, isEncrypted = false) => {
  let search = window.location.search
  if (isEncrypted) {
    //removing '?' which comes from url before decrypting
    if (search.startsWith("?")) search = search.slice(1)
    search = decryptUrlParams(search)
    search = `?${search}`
  }
  let params = new URLSearchParams(search)
  let res = params.get(key)
  res = res === "null" ? null : res
  return res
}

export const getFilterType = (input: string = "") => {
  let _filter = input?.toUpperCase()
  if (_filter === "CHOOSE1") {
    return "includesSome"
  } else if (_filter === "CONTAINS") {
    return "text"
  } else if (_filter === "GREATER_OR_LESS_THAN") {
    return "between"
  } else if (_filter === "EXACT_MATCH") {
    return "exactText"
  } else {
    return "text"
  }
}

////////// url encryption and decryption
export const encryptUrlParams = myString => {
  const encodedWord = crypto.enc.Utf8.parse(myString) // encodedWord Array object
  const encoded = crypto.enc.Base64.stringify(encodedWord) // string: 'NzUzMjI1NDE='
  return encoded
}

export const decryptUrlParams = encoded => {
  try {
    const encodedWord = crypto.enc.Base64.parse(encoded) // encodedWord via Base64.parse()
    const decoded = crypto.enc.Utf8.stringify(encodedWord) // decode encodedWord via Utf8.stringify() '75322541'
    return decoded
  } catch (err) {
    return ""
  }
}

export const getPropValue = props => {
  return props.value ? props.value : "-"
}

export const getDateTimeFormat = props => {
  const date = moment.utc(props.value).format("DD-MMM-YYYY").toLocaleUpperCase()
  const time = moment.utc(props.value).local().format("hh:mm A")
  return date + " " + time
}
export const handleFocus = event => event.target.select()

export const getAllElementsByClassName = (className: string) => {
  return Array.from(document.getElementsByClassName(className))
}
export const showVarianceTab = configData => {
  if (
    !configData?.hasOwnProperty("GroupedVariances") &&
    !configData?.hasOwnProperty("FullStoreVariances")
  ) {
    return false
  }
  if (
    configData.hasOwnProperty("FullStoreVariances") &&
    configData?.FullStoreVariances
  ) {
    if (
      configData?.FullStoreVariances.length &&
      configData?.FullStoreVariances[0].Enabled
    )
      return true
  }
  if (
    configData.hasOwnProperty("GroupedVariances") &&
    configData?.GroupedVariances
  ) {
    if (
      configData?.GroupedVariances.length &&
      configData?.GroupedVariances[0].Enabled
    )
      return true
  }
  return false
}

export const checkIsAlphaFromConfig = configData => {
  const countPrograms = configData?.CountPrograms || []
  const firstProgram = countPrograms[0]

  if (
    Array.isArray(firstProgram) &&
    firstProgram.some(program => program?.Location?.IsAlpha)
  ) {
    return true
  } else {
    return false
  }
}
const subString1 = 6
const subString2 = 7
const subString3 = 2
// Checking for Range Overlap Message
export const getOverlapMessage = msg => {
  const s1 = msg
    .substring(0, msg.indexOf("ranges") + subString1)
    .split(" ")
    .join("_")
  const s2 = msg.substring(msg.indexOf("ranges") + subString2, msg.indexOf("."))

  const s3 = msg
    .substring(msg.indexOf(".") + subString3)
    .split(" ")
    .join("_")
  return { s1, s2, s3 }
}
// Spliting string for multiline tags
export const splitString = str => {
  const parts = str?.split(",")
  return parts.join("\n")
}
export function translate(key) {
  if (i18next.exists(key)) {
    return i18next.t(key)
  } else {
    return ""
  }
}

export const encrypt = plaintext => {
  let SecretKey: string = getKeys("UrlEncryptionSecretKey")
  let IV: string = getKeys("UrlEncryptionIVKey")
  if (SecretKey && IV) {
    let keyWA = crypto.enc.Utf8.parse(SecretKey)
    const ivWA = crypto.enc.Utf8.parse(IV)
    const encrypted = crypto.AES.encrypt(plaintext, keyWA, { iv: ivWA })
    return encrypted.toString()
  }
  return ""
}
// Checking if  values from csv is empty
// Istanbul ignore next
const hasBlankValues = (arr, isAlpha) => {
  // For IsAlpha true
  if (isAlpha) {
    // Check if all values for "Location End" are empty
    const allEmpty = arr?.every(
      item => item[validationConstants.LOCATION_END] === ""
    )
    // Check if all values for "Location End" are non-empty
    const allNonEmpty = arr?.every(
      item => item[validationConstants.LOCATION_END] !== ""
    )
    // Determine if either all values are empty or all values are non-empty
    const isValid = allEmpty || allNonEmpty
    // Check if all column values are non empty excluding Location End Column
    const isEmptyOtherValues = arr?.some(item =>
      Object.entries(item)?.some(
        ([key, value]) =>
          key !== validationConstants.LOCATION_END && value === ""
      )
    )
    if (isValid && !isEmptyOtherValues) {
      return false
    } else {
      return true
    }
  }
  // For IsAlpha false
  else {
    return arr?.some(item => Object.values(item).some(value => value === ""))
  }
}

export const processCSV = (str: any, dispatch, configData) => {
  const parsedData = Papa.parse(str, {
    header: true,
    skipEmptyLines: true
  })
  const parsedArray = parsedData.data
  const newArray = parsedArray.map((row: any) => {
    const newRow = {}
    Object.keys(row).forEach(key => {
      let val = row[key]
      val = val.replace(/["\\]/g, "")
      val = val.replace(/,/g, "")

      // Handle values wrapped in double quotes
      if (val.charAt(0) === '"' && val.charAt(val.length - 1) === '"') {
        val = val.slice(1, -1) // Remove the double quotes
        val = val.replace(/""/g, '"') // Replace double double quotes with a single quote
      }
      // Remove escaping backslashes
      val = val.replace(/\\"/g, '"')
      newRow[key] = val
    })
    return newRow
  })
  if (newArray.length) {
    const _temp = Object.keys(newArray[0])
    const isAlphaConfig = checkIsAlphaFromConfig(configData)
    const isInvalidLength = _temp.length !== 4
    const isIsAlphaInvalidLength = _temp.length < 3

    const hasBlankVals = hasBlankValues(newArray, isAlphaConfig)

    if (hasBlankVals) {
      return 1
    } else if (!isAlphaConfig && isInvalidLength) {
      return 2
    } else if (isAlphaConfig && isIsAlphaInvalidLength) {
      return 3
    } else if (newArray.length < 1) {
      dispatch(setTagFileData([]))
      return 4
    } else {
      dispatch(setCsvColumnList(_temp))
      dispatch(setTagMappingData({}))
      dispatch(setTagFileData(JSON.stringify(newArray)))
      return 0
    }
  } else {
    return 4
  }
}

// Helper function to convert data URI to Blob
export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(",")[1])
  const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

export const convertDataURIToFile = (dataURI, filename) => {
  const blob = dataURItoBlob(dataURI)
  const file = new File([blob], filename)
  return file
}

export const formatTimeOnHover = (val, isADJ = false) => {
  if (val && val !== "-" && val !== INVALID_DATE) {
    const date = moment.utc(val).format("DD-MMM-YYYY")
    const timeFormat = isADJ ? "hh:mm A" : "hh:mm A"
    const time = moment.utc(val).local().format(timeFormat)
    return `${date.toUpperCase()} ${time}`
  } else {
    return "-"
  }
}

export const generateColumnsFromConfig = (config: any, langCode: string) => {
  let columns: any = []
  let locationColumn: any = null

  config?.Fields?.map((f: any) => {
    Object.keys(f).forEach((column: any) => {
      if (f[column].ShowOnTagDetails && f[column].DataField) {
        let dfield =
          f[column].DataField.charAt(0).toLowerCase() +
          f[column].DataField.slice(1)
        if (f[column].DataField === "ProductSize") {
          dfield = "size"
        }
        const columnData = {
          label: f[column].Labels[0][langCode],
          field: f[column].DataField.toLowerCase(),
          columnKey: column,
          dfield: dfield,
          sortOrder: f[column]?.SortOrder, // Add SortOrder to the column
          ...f[column]
        }

        if (
          column.toUpperCase() === TAG_DETAILS_HEADERS.LOCATION.toUpperCase()
        ) {
          locationColumn = columnData
        } else {
          columns.push(columnData)
        }
      }

      // add the Ext column
      if (
        column.toUpperCase() === TAG_DETAILS_HEADERS.EXT.toUpperCase() &&
        f[column].ShowOnTagDetails
      ) {
        columns.push({
          label: TAG_DETAILS_HEADERS.EXT,
          field: TAG_DETAILS_HEADERS.EXT,
          dfield: "ext",
          sortOrder: f[column]?.SortOrder // Add SortOrder to the column
        })
      }
    })
  })

  // Sort columns by SortOrder property, excluding the locationColumn
  columns?.sort((a: any, b: any) => (a.sortOrder || 0) - (b.sortOrder || 0))

  // Prepend the locationColumn to the sorted columns array if it exists
  if (locationColumn) {
    columns?.unshift(locationColumn)
  }

  return columns
}

export const apiCancellationLogic = (path, cancelTokenSources) => {
  // Create a new cancellation token for the current API request
  const cancelToken = axios.CancelToken.source()

  // Generate a unique property name for the cancellation token and its source
  const propertyName = path.replace(/\W+/g, "")

  // Store the new cancellation token and its source in the cancelTokenSources object
  const newCancelTokenSources = {
    ...cancelTokenSources,
    [propertyName]: cancelToken
  }

  // Cancel any previous request for the same API path
  if (cancelTokenSources[propertyName]) {
    cancelTokenSources[propertyName].cancel(
      propertyName + " Request canceled due to new API call, duplicate request"
    )
  }

  return {
    cancelTokenSources: newCancelTokenSources,
    propertyName: propertyName
  }
}

export function calculateProgressValue(progress, primaryGoal, secondaryGoal) {
  // Calculate the progress percentage
  const progressPercentage = (progress / primaryGoal) * 100

  // Calculate the secondary goal percentage
  const secondaryGoalPercentage = (secondaryGoal / primaryGoal) * 100

  let leftPosition

  if (progressPercentage <= secondaryGoalPercentage) {
    // In the red section
    leftPosition = (progressPercentage / secondaryGoalPercentage) * 25 // Red section is 25% of the bar
  } else if (progressPercentage <= 100) {
    // In the yellow section
    const yellowStart = 25 // Start of the yellow section in percentage
    const progressInYellow = progressPercentage - secondaryGoalPercentage
    const yellowRange = 100 - secondaryGoalPercentage
    const yellowWidth = 50 // Width of the yellow section in percentage

    leftPosition = yellowStart + (progressInYellow / yellowRange) * yellowWidth // Yellow section is 50% of the bar
  } else {
    // In the green section (progress greater than 100% of primary goal)
    const greenStart = 75 // Start of the green section in percentage
    const progressInGreen = progressPercentage - 100
    const greenRange = progressPercentage - 100
    const greenWidth = 25 // Width of the green section in percentage

    leftPosition = greenStart + (progressInGreen / greenRange) * greenWidth // Green section is 25% of the bar
  }

  return { positionPercent: `${leftPosition}%`, progressValue: leftPosition }
}

export const CLOSEOUT_CHECLIST_CODES = {
  DATA_PROCESS: "DATA_PROCESS",
  SYSTEM_AUDITS: "SYSTEM_AUDITS",
  AUDIT_PERCENTAGE: "AUDIT_PERCENTAGE",
  AUDITS_PER_COUNTER: "AUDITS_PER_COUNTER",
  VARIANCE_RESEARCH: "VARIANCE_RESEARCH",
  REPORT_CHECK_MARKS: "REPORT_CHECK_MARKS",
  WIS_SURVEY: "WIS_SURVEY",
  REPORT_SIGNATURE: "REPORT_SIGNATURE",
  PRE_COUNT_FILES: "PRE_COUNT_FILES"
}

export const CLOSUEOUT_CHECK_TYPES = {
  AllCheckMarks: "isChecked",
  AllResearchVariance: "countResearchVariance",
  NoDuplicate: "duplicateLocationStatus",
  NoVoidAndCounted: "voidAndCountedStatus",
  AuditsPerCounter: "auditPerCounterStatus",
  AuditPercentage: "requiredAuditPercentageStatus",
  SystemAudit: "systemAuditsStatus",
  AllDataProcessed: "updateRecordsStatus",
  DeviceLogoff: "deviceLoginStatus",
  OutOfRange: "outOfRangeCountedStatus",
  InventoryPercentage: "locationCountedStatus",
  WisSurvey: "wisSurveyStatus",
  ReportSignature: "reportSignatureStatus",
  PreCountFilesStatus: "allPrecountFilesLoaded"
}

export const FULL_VARIANCE_CHECKLIST = [
  {
    label: "Inventory_100percentage_Counted",
    statusKey: "locationCountedStatus",
    overrideKey: "locationCountedOverridenDetails",
    type: CLOSUEOUT_CHECK_TYPES.InventoryPercentage
  },
  {
    label: "NoOutofRange",
    statusKey: "outOfRangeCountedStatus",
    overrideKey: "outOfRangeCountedOverridenDetails",
    type: CLOSUEOUT_CHECK_TYPES.OutOfRange
  },
  {
    label: "All_System_Audits_Complete",
    statusKey: "systemAuditsStatus",
    overrideKey: "systemAuditsOverridenDetails",
    code: CLOSEOUT_CHECLIST_CODES.SYSTEM_AUDITS,
    type: CLOSUEOUT_CHECK_TYPES.SystemAudit
  },
  {
    label: "Audit_Percentage_Complete",
    statusKey: "requiredAuditPercentageStatus",
    overrideKey: "requiredAuditPercentageOverridenDetails",
    code: CLOSEOUT_CHECLIST_CODES.AUDIT_PERCENTAGE,
    type: CLOSUEOUT_CHECK_TYPES.AuditPercentage
  },
  {
    label: "NoVoidedandCounted",
    statusKey: "voidAndCountedStatus",
    overrideKey: "voidAndCountedOverridenDetails",
    type: CLOSUEOUT_CHECK_TYPES.NoVoidAndCounted
  },
  {
    label: "NoDuplicate",
    statusKey: "duplicateLocationStatus",
    overrideKey: "duplicateLocationOverridenDetails",
    isLabelDynamic: true,
    type: CLOSUEOUT_CHECK_TYPES.NoDuplicate
  },
  {
    label: "Group_Variance_Research_is_Complete",
    statusKey: "countResearchVariance",
    overrideKey: "countResearchVarianceOverridenDetails",
    code: CLOSEOUT_CHECLIST_CODES.VARIANCE_RESEARCH,
    type: CLOSUEOUT_CHECK_TYPES.AllResearchVariance
  }
]

export const CLOSEOUT_CHECLIST = [
  {
    label: "Inventory_100percentage_Counted",
    statusKey: "locationCountedStatus",
    overrideKey: "locationCountedOverridenDetails",
    showBydefault: true,
    type: CLOSUEOUT_CHECK_TYPES.InventoryPercentage
  },
  {
    label: "NoOutofRange",
    statusKey: "outOfRangeCountedStatus",
    overrideKey: "outOfRangeCountedOverridenDetails",
    showBydefault: true,
    type: CLOSUEOUT_CHECK_TYPES.OutOfRange
  },
  {
    label: "All_devices_are_logged_off",
    statusKey: "deviceLoginStatus",
    overrideKey: "deviceLoginOverridenDetails",
    showBydefault: true,
    type: CLOSUEOUT_CHECK_TYPES.DeviceLogoff
  },
  {
    label: "All_Data_Processed",
    statusKey: "updateRecordsStatus",
    overrideKey: "insertRecordsOverridenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.DATA_PROCESS,
    type: CLOSUEOUT_CHECK_TYPES.AllDataProcessed
  },
  {
    label: "All_System_Audits_Complete",
    statusKey: "systemAuditsStatus",
    overrideKey: "systemAuditsOverridenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.SYSTEM_AUDITS,
    type: CLOSUEOUT_CHECK_TYPES.SystemAudit
  },
  {
    label: "Audit_Percentage_Complete",
    statusKey: "requiredAuditPercentageStatus",
    overrideKey: "requiredAuditPercentageOverridenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.AUDIT_PERCENTAGE,
    type: CLOSUEOUT_CHECK_TYPES.AuditPercentage
  },
  {
    label: "Audits_per_Counter_Complete",
    statusKey: "auditPerCounterStatus",
    overrideKey: "auditPerCounterOverridenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.AUDITS_PER_COUNTER,
    type: CLOSUEOUT_CHECK_TYPES.AuditsPerCounter
  },
  {
    label: "NoVoidedandCounted",
    statusKey: "voidAndCountedStatus",
    overrideKey: "voidAndCountedOverridenDetails",
    showBydefault: true,
    type: CLOSUEOUT_CHECK_TYPES.NoVoidAndCounted
  },
  {
    label: "NoDuplicate",
    statusKey: "duplicateLocationStatus",
    overrideKey: "duplicateLocationOverridenDetails",
    isLabelDynamic: true,
    showBydefault: true,
    type: CLOSUEOUT_CHECK_TYPES.NoDuplicate
  },
  {
    label: "All_Required_variances_are_Research_Complete",
    statusKey: "countResearchVariance",
    overrideKey: "countResearchVarianceOverridenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.VARIANCE_RESEARCH,
    type: CLOSUEOUT_CHECK_TYPES.AllResearchVariance
  },
  {
    label: "All_check_marks_done_for_interim_reports",
    statusKey: "isChecked",
    overrideKey: "isCheckedOverridenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.REPORT_CHECK_MARKS,
    type: CLOSUEOUT_CHECK_TYPES.AllCheckMarks
  },
  {
    label: "WIS_Customer_Satisfaction_Survey_Completed",
    statusKey: "wisSurveyStatus",
    overrideKey: "wisSurveyStatusOverriddenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.WIS_SURVEY,
    type: CLOSUEOUT_CHECK_TYPES.WisSurvey
  },
  {
    label: "Signatures_required_on_Final_Reports",
    statusKey: "reportSignatureStatus",
    overrideKey: "reportSignatureStatusOverriddenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.REPORT_SIGNATURE,
    type: CLOSUEOUT_CHECK_TYPES.ReportSignature,
    signedReportsCount: "signedReportsCount",
    signRequiredReportCount: "signRequiredReportCount"
  },
  {
    label: "Pre_Count_data_processed",
    statusKey: "allPrecountFilesLoaded",
    overrideKey: "allPrecountFilesLoadedOverriddenDetails",
    showBydefault: false,
    code: CLOSEOUT_CHECLIST_CODES.PRE_COUNT_FILES,
    type: CLOSUEOUT_CHECK_TYPES.PreCountFilesStatus
  }
]
export const formatPhoneNumber = phoneNumberString => {
  const temp = phoneNumberString?.includes("+1")
    ? phoneNumberString.replace("+1", "")
    : phoneNumberString
  const cleaned = ("" + temp).replace(/\D/g, "")
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/)
  if (match && phoneNumberString.length <= 12) {
    const intlCode = "+1"
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("")
  }
  return null
}

export const getPayload = (
  formValues,
  tvalue,
  multiListRanges,
  isOverlapConfirmed
) => {
  const description: string[] =
    formValues?.tags &&
    formValues?.tags.map((tag: any) => `${tag.start}-${tag.end}`)
  const rangeDesc: string = description?.join(",")
  const trimmedMultiRanges = multiListRanges
    .split(",")
    .map(t => t.trim())
    .join(",")
  return {
    idCustomer: 0,
    rangeDesc:
      tvalue === TagConstants.TAGRANGE ? rangeDesc : trimmedMultiRanges,
    idEvent: 0,
    idCategory: formValues.tag_category,
    rangeName: formValues.range_desc,
    confirmation: isOverlapConfirmed,
    isAlpha: tvalue === TagConstants.TAGRANGE ? false : true
  }
}

export const reducer = (state, action) => {
  // If action is a function, call it with the current state and return the result
  if (typeof action === "function") {
    return action(state)
  }

  // Otherwise, toggle the state
  return !state
}

export const localTimeAsUTC = (date = new Date()) => {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}T${String(
    date.getHours()
  ).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(
    date.getSeconds()
  ).padStart(2, "0")}.${String(date.getMilliseconds()).padStart(3, "0")}Z`
}

export const getLastFileExtension = filename => filename.split(".").pop()

const getAllFileExtension = filename => {
  if (filename.split(".").length > 1) {
    const allExtension = filename.match(/\.[^.]+/g)
    let newAllExtension: any = []
    allExtension.forEach(str => {
      if (!/^[a-zA-Z]+$/.test(str.substring(1))) {
        newAllExtension = []
      } else {
        newAllExtension.push(str.toLowerCase())
      }
    })
    return newAllExtension
  }

  return filename
}

const isFileNameAllowed = ({ fileName, setAlertError, t, Translates }) => {
  const allExtension = getAllFileExtension(fileName)
  for (const extension of disallowedExtensions) {
    if (allExtension.includes(extension.toLowerCase())) {
      setAlertError(oldVal => ({
        ...oldVal,
        severity: "error",
        title: t(Translates.Incorrect_File_Format),
        content: `${t(
          Translates.File_name_or_extension_can_not_be_contain_the_following_letters
        )} ${disallowedExtensions.join(",")}`
      }))
      return false
    }
  }
  return true
}

export const fileExtensionCheck = ({
  fileName,
  setAlertError,
  t,
  Translates
}) => {
  if (!isFileNameAllowed({ fileName, setAlertError, t, Translates })) {
    return false
  }
  return true
}

export const sortTagInstances = (data: any = []) => {
  data = data || []
  return [...data].sort((a, b) => {
    return b.locationInstanceId - a.locationInstanceId
  })
}

const specialCharsRegex = /[@$*%\/\\]/

export const validateInputString = (inputStr: string) => {
  if (!inputStr) return false
  if (specialCharsRegex.test(inputStr)) {
    return false
  } else {
    return true
  }
}

export const createHash = (password: string): string => {
  const hashedPassword = crypto.SHA256(password)
  return hashedPassword.toString()
}
let clearAllStates: any

async function getAllStatesClear() {
  if (!clearAllStates) {
    // Import clearAllStates only when needed
    const { clearAllStates: clearAll } = await import("src/pages/Count/Helper")
    clearAllStates = clearAll
  }
  return clearAllStates
}

export const logOut = () => {
  getAllStatesClear()
  localStorage.clear()
  window.location.href = "/login"
}

export const parseToken = token => {
  if (!token) return ""
  try {
    const _base64Payload = token.split(".")[1]
    const _payloadBuffer = Buffer.from(_base64Payload || "", "base64")
    const parsedObj = JSON.parse(_payloadBuffer.toString())
    return parsedObj
  } catch (_err) {
    logOut()
    return ""
  }
}

export const getTranslations = (t, Translates, key) => {
  return t(Translates[key] ?? key)
}

export const setLoadingValue = ({ status, variableName, setLoadings }) => {
  if (["failed", ""].includes(status)) {
    setLoadings(oldV => ({ ...oldV, [variableName]: false }))
  } else if (["success"].includes(status)) {
    setLoadings(oldV => ({ ...oldV, [variableName]: true }))
  }
}

export const capitalizeFirstString = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}

export const polishedValues = (str, explength, returnValue = "") => {
  if (str.length > explength) {
    let splitLength = str.length / explength
    for (let i = 0; i <= splitLength; i++) {
      if (i == splitLength) {
        return (returnValue =
          returnValue +
          "</br>" +
          str.slice(i * explength + 1, str.length - 1) +
          "</span>")
      }
      if (returnValue == "") {
        returnValue = "<span>" + str.slice(i, explength)
      } else {
        returnValue =
          returnValue +
          "</br>" +
          str.slice(i * explength, i * explength + explength)
      }
    }

    return returnValue + "</span>"
  } else {
    return str
  }
}
export const validateInteger = fieldValue => {
  const regex = /^\d+$/
  if (!fieldValue) {
    return false
  } else {
    return regex.test(fieldValue)
  }
}

export const getOverallStatusCls = status => {
  if (status) {
    if (status?.toLowerCase() === TAG_STATUS.VERIFIED.toLowerCase())
      return "status-bg success audit-status"
    if (status?.toLowerCase() === TAG_STATUS.NOTSTARTED.toLowerCase())
      return "count-status status-bg"
    if (status?.toLowerCase() === TAG_STATUS.COUNTED.toLowerCase())
      return "status-bg success count-status"
    if (status?.toLowerCase() === TAG_STATUS.VOIDED.toLowerCase())
      return "status-bg count-status error"
  }
}
